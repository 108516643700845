import React, {useState} from 'react'
import { Outlet, Link } from "react-router-dom";
import '../../assets/css/pagina_web.css'
import pagina_web from '../../assets/images/pagina_web.jpg'
import html from '../../assets/images/html.png'
import css from '../../assets/images/css.png'
import js from '../../assets/images/js.png'
import react from '../../assets/images/react.png'
import node from '../../assets/images/node.png'
import postgresql from '../../assets/images/postgresql.png'
import mysql from '../../assets/images/mysql.png'
import landingpage from '../../assets/images/landingpage.webp'
import onepage from '../../assets/images/one-page.webp'
import multipage from '../../assets/images/multipage.webp'
import auditoria from '../../assets/images/auditoria.jpg'

import facturacion from '../../assets/images/pagina-web/seguridad-informatica.png'
import helpdesk from '../../assets/images/pagina-web/correo-electronico.png'
import gestion from '../../assets/images/pagina-web/solucion-de-problemas.png'
import migracion from '../../assets/images/pagina-web/capacitacion.png'
import axios from 'axios';
import Footer from '../../Footer'

export default function PaginaWeb() {
    const [activeInfo, setActiveInfo] = useState('one-page');

    const handleInfoClick = (id) => {
        if (activeInfo === id) {
            setActiveInfo(null);
        } else {
            setActiveInfo(id);
        }
    };
    const [nombre, setNombre] = useState('');
	const [empresa, setEmpresa] = useState('');
	const [telefono, setTelefono] = useState('');
    const [servicio, setServicio] = useState('');
	const [email, setEmail] = useState('');
	const [mensaje, setMensaje] = useState('');
	const [aceptoPoliticas, setAceptoPoliticas] = useState(true);

	const [alert, setAlert] = useState(false);
 {/*await axios.post('http://localhost:3001/enviar-correo', {*/}

	const enviarCorreo = async () => {

        try {
            await axios.post('https://3cwma7t8s4.execute-api.us-east-1.amazonaws.com/prod/webservice', {
                nombre,
                empresa,
                telefono,
				servicio,
                email,
                mensaje,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    // Agrega otros encabezados si son necesarios
                },
            });
		
    		
            // Limpiar campos después de enviar el correo
            setNombre('');
            setEmpresa('');
            setTelefono('');
			setServicio('');
            setEmail('');
            setMensaje('');
    
            setAlert(true);
        } catch (error) {
            console.error('Error al enviar el correo:', error);
            alert('Error al enviar el correo');
        }
    };

  return (
    <>
      <section className='headers'>
          <div className='container'>
              <div className='title'>
                  <h1>Página web</h1>
                  <p>Nuestro propósito es lo que nos hace levantarnos cada mañana, <br/> y esforzarnos por ofrecer un futuro más digital. </p>
              </div>
          </div>
      </section>
      <section>
        <div className='container'>

            <div class="content_about">
				<div class="about_img">
					<img src={pagina_web} alt='About univesepage' />
				</div>
				<div class="about_text_box">
					<div class="about_text">
                        <h2 style={{textAlign:'initial'}}>TRANSFORMANDO IDEAS EN SOLUCIONES DIGITALES</h2>
						<h4>PÁGINAS WEB</h4>
						<p>Nos especializamos en la creación de <strong>páginas web personalizadas</strong> que se ajustan a las necesidades específicas de cada cliente. Nuestro equipo de expertos se encargará de cada detalle para asegurarse de que su presencia en línea refleje la esencia única de su negocio.</p>
						<p>La implementación de <strong>metodologías ágiles</strong> y las mejores prácticas de desarrollo nos permiten ofrecer productos de alta calidad en plazos eficientes. Estamos aquí para transformar tus ideas en soluciones digitales impactantes y escalables. </p>						
						<a href="#categorias" class="btn_about"  aria-label="categorias universepage">Ver categorias</a>
                        
					</div>
					
				</div>
			</div>
        </div>
    </section>
    <section className='categorias' id='categorias'>
        <div className='container'>
            <div className='title_categorias'>
                <h3>Tipos de páginas web</h3>
                <div className='description_categorias'>
                    <p>Las páginas web se pueden categorizar de diversas maneras según su estructura, propósito y funcionalidades. Algunas de las categorías comunes son:</p>
                </div>                
            </div>
            <div className='categoria_container'>
                <div className='categoria'>
                    
                    <div  className={`box_categoria ${activeInfo === 'one-page' ? 'active' : ''}`} id='one-page' onClick={() => handleInfoClick('one-page')}>
                        <div className='title_categoria'>
                            <h3>One Page (página única)</h3>                            
                        </div>
                    </div>
                    <div  className={`box_categoria ${activeInfo === 'multipage' ? 'active' : ''}`} id='multipage' onClick={() => handleInfoClick('multipage')}>  
                        <div className='title_categoria'>
                            <h3>Multipage (multipágina)</h3>
                        </div>
                    </div>
                    <div className={`box_categoria ${activeInfo === 'landing-page' ? 'active' : ''}`} id='landing-page' onClick={() => handleInfoClick('landing-page')}>
                        <div className='title_categoria'>
                            <h3>Landing page (página de aterrizaje) </h3>
                        </div>
                    </div>                    
                </div>   
                <div className='content_categoria'>
                    {activeInfo === 'one-page' && (
                        <div className='info_visible'>                            
                            
                            <div className='info_visible_content'>
                                <div className='info_visible_box'>                                    
                                    <i class="fi fi-rs-sitemap"></i>                                    
                                    <h4>Estructura </h4>
                                    <p>Toda la información y contenido están contenidos en una sola página web.</p>                                    
                                </div>
                                <div className='info_visible_box'>
                                    <i class="fi fi-rs-laptop-mobile"></i>
                                    <h4>Navegación </h4>
                                    <p>La navegación se realiza mediante enlaces que desplazan al usuario hacia diferentes secciones dentro de la misma página en lugar de cargar nuevas páginas.</p>
                                </div>
                                <div className='info_visible_box'>
                                    <i class="fi fi-rs-pencil-paintbrush"></i>
                                    <h4>Experiencia de Usuario </h4>
                                    <p>La navegación es fluida y continua, lo que puede ofrecer una experiencia más interactiva y cohesiva.</p>
                                </div>
                            </div>
                            
                        </div>
                    )}

                    {activeInfo === 'multipage' && (
                        <div className='info_visible'>                            
                            <div className='info_visible_content'>
                                <div className='info_visible_box'>
                                    <i class="fi fi-rs-sitemap"></i>      
                                    <h4>Estructura </h4>                                    
                                    <p>La información y el contenido están distribuidos en varias páginas web separadas.</p>                                    
                                </div>
                                <div className='info_visible_box'>
                                    <i class="fi fi-rs-laptop-mobile"></i>
                                    <h4>Navegación </h4>
                                    <p>  Los enlaces de navegación llevan a los usuarios a páginas diferentes, cada una con su propia URL. Puede incluir menús de navegación más complejos con submenús.</p>
                                </div>
                                <div className='info_visible_box'>
                                    <i class="fi fi-rs-pencil-paintbrush"></i>
                                    <h4>Experiencia de Usuario </h4>
                                    <p>Permite organizar grandes cantidades de información de manera más ordenada y específica, facilitando el acceso a información detallada y compleja.</p>
                                </div>
                            </div>
                        </div>
                    )}

                    {activeInfo === 'landing-page' && (
                        <div className='info_visible'>                                                        
                            <div className='info_visible_content'>
                                <div className='info_visible_box'>
                                    <i class="fi fi-rs-sitemap"></i>      
                                    <h4>Estructura </h4>
                                    <p>Enfocada en un solo mensaje o propósito, y toda la página está diseñada para guiar al usuario hacia una acción específica.</p>
                                </div>
                                <div className='info_visible_box'>
                                    <i class="fi fi-rs-laptop-mobile"></i>
                                    <h4>Navegación </h4>
                                    <p>Minimiza o elimina la navegación para evitar que los usuarios se desvíen del objetivo principal.</p>
                                    
                                </div>
                                <div className='info_visible_box'>
                                    <i class="fi fi-rs-pencil-paintbrush"></i>
                                    <h4>Llamada a la Acción (CTA)</h4>
                                    <p> El objetivo principal de un CTA es convertir visitantes en leads o clientes, incitándolos a realizar una acción concreta como registrarse, comprar, descargar, suscribirse, etc.</p>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div>
    </section>
    <section className='planes_web'>
        <div className='container'>
            <div className='planes_web_container'>
                <div className='planes_web_content'>
                    <div className='box_plan_content'>
                        <div className='box_plan'>
                            <div>
                                <h3>Profecional</h3>
                                <label><i class="fi fi-br-browser"></i> Hasta 5 secciones de contenido (1 pestaña)</label>
                                <label><i class="fi fi-br-time-check"></i>Tiempo de entrega: 15 días</label>                        
                            </div>
                            <div className='box_plan_precio'>
                                <p>$12,600 <label>Pago unico</label></p>
                                
                                <div style={{marginBottom: '1rem'}}>                                    
                                    <a href="https://wa.me/5216121970006?text=Me%20interesa%20una%20cotizaci%C3%B3n%20plan:%20Profecional" class="btn_about" target="_blank" rel="noopener noreferrer" aria-label="Cotizar por WhatsApp">Cotizar ahora</a>
                                </div>
                            </div>
                        </div>
                        <div className='box_plan'>                            
                            <div>
                                <h3>Empresarial</h3>
                                <label><i class="fi fi-br-browser"></i> Hasta 7 secciones de contenido (5 pestañas)</label>
                                <label><i class="fi fi-br-time-check"></i>Tiempo de entrega: 15 días</label>                        
                            </div>
                            <div className='box_plan_precio'>
                                <p>$15,600 <label>Pago unico</label></p>
                                <div>
                                <a href="https://wa.me/5216121970006?text=Me%20interesa%20una%20cotizaci%C3%B3n%20plan:%20Empresarial" class="btn_about" target="_blank" rel="noopener noreferrer" aria-label="Cotizar por WhatsApp">Cotizar ahora</a>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className='box_plan_content'>
                        <div className='box_plan'>
                            <div>
                                <h3>Básico (WordPress)</h3>
                                <label><i class="fi fi-br-browser"></i> Hasta 5 secciones de contenido (1 pestaña)</label>
                                <label><i class="fi fi-br-time-check"></i>Tiempo de entrega: 15 días</label>                        
                            </div>
                            <div className='box_plan_precio'>
                                <p>$10,800 <label>Pago unico</label></p>
                                <div>
                                <a href="https://wa.me/5216121970006?text=Me%20interesa%20una%20cotizaci%C3%B3n%20plan:%20Basico" class="btn_about" target="_blank" rel="noopener noreferrer" aria-label="Cotizar por WhatsApp">Cotizar ahora</a>
                                </div>
                            </div>
                        </div>
                        <div className='box_plan'>
                            <div>
                                <h3>Avanzado (WordPress)</h3>
                                <label><i class="fi fi-br-browser"></i> Hasta 7 secciones de contenido (5 pestañas)</label>
                                <label><i class="fi fi-br-time-check"></i>Tiempo de entrega: 15 días</label>                        
                            </div>
                            <div className='box_plan_precio'>
                                <p>$14,600 <label>Pago unico</label></p>
                                <div style={{marginBottom: '1rem'}}>
                                    <a href="https://wa.me/5216121970006?text=Me%20interesa%20una%20cotizaci%C3%B3n%20plan:%20Avanzado" class="btn_about" target="_blank" rel="noopener noreferrer" aria-label="Cotizar por WhatsApp">Cotizar ahora</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='planes_web_content'>
                    <div className='planes_web_box'>
                        <h3>Todos los planes incluyen:</h3>
                        <ul>
                            <li><i class="fi fi-rr-arrow-circle-right"></i> Diseño (UX), Maquetación y Desarrollo del proyecto</li>
                            <li><i class="fi fi-rr-arrow-circle-right"></i> Diseño responsivo</li>
                            <li><i class="fi fi-rr-arrow-circle-right"></i> Administrador de contenidos (WordPress)</li>
                            <li><i class="fi fi-rr-arrow-circle-right"></i> Optimización para SEO</li>
                            <li><i class="fi fi-rr-arrow-circle-right"></i> Imágenes de stock</li>
                            <li><i class="fi fi-rr-arrow-circle-right"></i> Forma de contacto</li>
                            <li><i class="fi fi-rr-arrow-circle-right"></i> Código optimizado para velocidad</li>
                            <li><i class="fi fi-rr-arrow-circle-right"></i> Integración con redes sociales (opcional)</li>
                            <li><i class="fi fi-rr-arrow-circle-right"></i> Mapa de ubicación Google Maps (opcional)</li>                            
                            <li><i class="fi fi-rr-arrow-circle-right"></i> Soporte y capacitación</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className='nota_web_service_content'>
        <div className='container'>
            <p className='nota_web_service'>El servicio de administración de sitios se cotiza por separado, <Link to="/gestion-web" >ver más aquí. </Link> </p>
        </div>
    </section>
    <section className='beneficios'>
		<div className='container'>
			<div class="beneficios_planes_title">
				<h2>Todos los planes incluyen </h2>
                <p>Nuestros desarrollos web ofrecen diferentes beneficios que pueden facilitar tu presencia </p>
			</div>
			<div className='beneficios_planes'>
				<div className='box_beneficios_planes'>
					<div><img src={facturacion} alt='Help desk'/></div>
					<div className='box_beneficios_title'>Certificado de seguridad SSL</div>
					<div>Nuestros servicios incluyen un certificado de seguridad gratuito.</div>
				</div>
				<div className='box_beneficios_planes'>
					<div><img src={helpdesk } alt='Help desk'/></div>					
					<div className='box_beneficios_title'>Correo empresarial</div>
					<div>Tus cuentas de correo electrónico personalizado desde cualquier lugar, rápido y fácil. </div>
				</div>
				<div className='box_beneficios_planes'>
					<div><img src={gestion} alt='Help desk'/></div>
					<div className='box_beneficios_title'>Soporte</div>
					<div>Personal con experiencia técnica y ejecutiva para la gestion de tu infraestructura.</div>
				</div>
				<div className='box_beneficios_planes'>
					<div><img src={migracion} alt='Help desk'/></div>
					<div className='box_beneficios_title'>Capacitación</div>
					<div>Te brindaremos sesiones personalizadas donde obtendras conocimientos necesarios para comprender tu sitio.</div>
				</div>

			</div>

		</div>
	</section>
    <section>
		<div class="container">
			<div class="contact">
				<div class="contact_info">
                    <img src={auditoria} alt='About univesepage' />
					<div>
                    <p><i class="fi fi-rr-angle-circle-right"></i> Diseño UX/UI.</p>
                        <p><i class="fi fi-rr-angle-circle-right"></i> Optimización de velocidad de carga.</p>
                        <p><i class="fi fi-rr-angle-circle-right"></i> Formulario de contacto.</p>
                        <p><i class="fi fi-rr-angle-circle-right"></i> SEO básico.</p>
                        <p><i class="fi fi-rr-angle-circle-right"></i> Conexión con redes sociales.</p>
					</div>					
				</div>
				<div class="formulario">
						<div>
							<h2>Envíanos tus dudas o comentarios</h2>
							<p>Completa el formulario de contacto a continuación y cuéntanos lo que necesitas. Estaremos encantados de responder a tus preguntas, proporcionarte asistencia personalizada o brindarte más detalles sobre nuestros servicios.</p>
						</div>
						{alert &&
							<div className='alertMessage'>
								<label>¡Tu formulario ha sido enviado con éxito!</label>
								<p> Nuestro equipo está revisando la información, un agente se pondrá en contacto contigo pronto.</p>
							</div>
						}						
						<div class="form-group">
							<input
								type="text"
								placeholder="Tu nombre"
								value={nombre}
								onChange={(e) => setNombre(e.target.value)}
							/>
							<input
								type="text"
								placeholder="Nombre de tu empresa (opcional)"
								value={empresa}
								onChange={(e) => setEmpresa(e.target.value)}
							/>
							
						</div>
						<div class="form-group">
							<input type='text'
								value={telefono}
								onChange={(e) => setTelefono(e.target.value)} 
								placeholder="Teléfono" />

							<select value={servicio}
								onChange={(e) => setServicio(e.target.value)}>
								<option>¿Qué servicio te interesa?</option>
								<option>Página web</option>
								<option>Tienda en línea</option>
								<option>Desarrollos web personalizados</option>
								<option>Dominio, Hosting y Correo</option>
								<option>Gestión de contenido web</option>
								<option>Auditoría web</option>
								<option>Otro tema</option>
								<option>No lo tengo definido</option>								
							</select>
						</div>
						<div>
							<input type="email"
								placeholder="Email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								/>
						</div>
						<div>
							<textarea 
								placeholder="¿Cómo podemos ayudarte?"
								value={mensaje}
								onChange={(e) => setMensaje(e.target.value)}
								>
							</textarea>
						</div>
						<div className='term-cond'>
							<input type="checkbox" 
							name=""
							checked={aceptoPoliticas}
							onChange={() => setAceptoPoliticas(!aceptoPoliticas)}
							/> Acepto el aviso legal y las política de privacidad. 
						</div>
						<div class="">
							
							<button
								class="btn_enviar"
								disabled={!aceptoPoliticas}
								onClick={enviarCorreo}
								>
								Enviar
								</button>

						</div>
					</div>
			</div>
		</div>
	</section>
    {/*

                
    <section className='ventajas_section'>

        <div className='container'>
        <div className='ventajas_title'>
            <h4>Ventajas</h4>
            <div className='ventajas_title_text'>
                <p>Estas son algunas de las ventajas que debes saber si estas pensando en obtener una página web</p>
            </div>
            
        </div>
            <div className='ventajas'>
                <div className='box_ventajas'>
                    <div className='item_ventaja'>
                        <label>Accesibilidad:</label>
                        <p> Disponibilidad las 24 horas, los 7 días de la semana, permitiendo que personas de todo el mundo accedan a la información en cualquier momento.</p>
                    </div>
                    <div className='item_ventaja'>
                        <label>Comunicación Eficiente:</label> 
                        <p>Facilita la comunicación efectiva con audiencias específicas y la difusión rápida de información relevante.</p>
                    </div>
                    <div className='item_ventaja'>
                        <label>Credibilidad y Presencia Online:</label>
                        <p>Establece una presencia digital que contribuye a la credibilidad y visibilidad de individuos, empresas u organizaciones.</p>
                    </div>
                    <div className='item_ventaja'>
                        <label>Costos Reducidos:</label> 
                        <p>En comparación con los métodos tradicionales de publicidad y comunicación, mantener una página web puede ser más rentable.</p>
                    </div>
                </div>
                <div className='box_ventajas'>
                    <div className='item_ventaja'>
                        <label>Interacción y Retroalimentación:</label>
                        <p>Proporciona una plataforma para interactuar con los usuarios, recibir comentarios y adaptarse a las necesidades de la audiencia.</p>
                    </div>
                    <div className='item_ventaja'>
                        <label>Actualizaciones y Novedades:</label> 
                        <p>Permite la actualización constante de información, manteniendo a los visitantes informados sobre cambios, noticias y eventos.</p>
                    </div>
                    <div className='item_ventaja'>
                        <label>Marketing y Promoción:</label> 
                        <p>Facilita estrategias de marketing digital, promoción de productos o servicios, y la expansión de la base de clientes.</p>
                    </div>
                    <div className='item_ventaja'>
                        <label>Accesibilidad Global:</label> 
                        <p>Disponibilidad las 24 horas, los 7 días de la semana, permitiendo que personas de todo el mundo accedan a la información en cualquier momento.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    */}
    <section className='herramientas_tecnologias'>
        <div className='container'>
            <div className='herramientas'>
                <div className='herramientas_text'>
                    <h4>HERRAMIENTAS Y TECNOLOGIAS</h4>
                    <div className='herramientas_text_content'>
                        <p>Nuestras habilidades y experiencia nos permiten ofrecer soluciones web a través de diversas tecnologías para el desarrollo de software, atractivas, robustas, seguras y escalables.</p>
                    </div>
                   

                </div>
                <div className='herramientas_img'>
                    <div className='herramientas_box'>
                        <img src={html} alt='html' />
                        <p>HTML</p>
                    </div>
                    <div className='herramientas_box'>
                        <img src={css} alt='css' />
                        <p>CSS</p>
                    </div>
                    <div className='herramientas_box'>
                        <img src={js} alt='java script' />
                        <p>JavaScript</p>
                    </div>
                    <div className='herramientas_box'>
                        <img src={react} alt='react' />
                        <p>React</p>
                    </div>
                    <div className='herramientas_box'>
                        <img src={node} alt='Node Js' />
                        <p>Node JS</p>
                    </div>
                    <div className='herramientas_box'>
                        <img src={postgresql} alt='PostgreSQL' />
                        <p>PostgreSQL</p>
                    </div>
                    <div className='herramientas_box'>
                        <img src={mysql} alt='mySQL' />
                        <p>MySQL</p>
                    </div>
                </div>
                <div className='herramientas_text'>                    
                    <div className='herramientas_text_content'>
                        <p></p>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <Outlet />
    <Footer />
    </>
  )
}
