import React, {useState}  from 'react'
import axios from 'axios';
import '../../assets/css/hosting.css'
import pagina_web from '../../assets/images/hosting.webp'
import auditoria from '../../assets/images/auditoria.jpg'
import cpanel from '../../assets/images/hosting/cpanel.png'
import helpdesk from '../../assets/images/hosting/soporte-tecnico.png'
import gestion from '../../assets/images/hosting/gestion.png'
import migracion from '../../assets/images/hosting/migracion.png'
import facturacion from '../../assets/images/hosting/facturacion.png'
import Footer from '../../Footer'

export default function Hosting() {
    const [nombre, setNombre] = useState('');
	const [empresa, setEmpresa] = useState('');
	const [telefono, setTelefono] = useState('');
    const [servicio, setServicio] = useState('');
	const [email, setEmail] = useState('');
	const [mensaje, setMensaje] = useState('');
	const [aceptoPoliticas, setAceptoPoliticas] = useState(true);

	const [alert, setAlert] = useState(false);
 {/*await axios.post('http://localhost:3001/enviar-correo', {*/}

	const enviarCorreo = async () => {

        try {
            await axios.post('https://3cwma7t8s4.execute-api.us-east-1.amazonaws.com/prod/webservice', {
                nombre,
                empresa,
                telefono,
				servicio,
                email,
                mensaje,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    // Agrega otros encabezados si son necesarios
                },
            });
		
    		
            // Limpiar campos después de enviar el correo
            setNombre('');
            setEmpresa('');
            setTelefono('');
			setServicio('');
            setEmail('');
            setMensaje('');
    
            setAlert(true);
        } catch (error) {
            console.error('Error al enviar el correo:', error);
            alert('Error al enviar el correo');
        }
    };
  return (
    <>
    <section className='headers'>
        <div className='container'>
            <div className='title'>
                <h1>Hosting y Correo</h1>
                <p>Haz que tu sitio web despegue con nuestro hosting rápido y seguro </p>
            </div>
        </div>
    </section>
    <section>
      	<div className='container'>
          	<div class="content_about">
				<div class="about_img">
					<img src={pagina_web} alt='About univesepage' />
				</div>
				<div class="about_text_box">
					<div class="about_text">
						<h2 style={{textAlign:'initial'}}>CONÉCTATE AL MUNDO CON NUESTRO HOSTING</h2>
						<h4>HOSTING Y CORREOS </h4>						
						<p>Te ofrecemos Servidores de alta velocidad y rendimiento para tu sitio web y soluciones de correo electrónico seguras.</p>
						<p>	
						Los servidores que gestionamos garantizan un rendimiento excepcional para tu sitio web, proporcionando tiempos de carga rápidos y una experiencia de usuario óptima.
						</p>
						<a href="#planess" class="btn_about"  aria-label="categorias universepage">Ver planes</a>
					</div>
					
				</div>
          	</div>
      	</div>
  </section>

	<section id='planess'>
        <div className='container'>
            <div className='plans_hosting'>
                <div className='plans'>
                    <h1>Planes y precios</h1>
                    <p>Elige el plan que mejor se adapte a tus necesidades</p>
                </div>
                <div className='planes_hosting'>
                    <div className='plan_uno'>
                        <div className='plan_amount'>
                            <h2>Inicial</h2>
							<label>Para aquellos que están iniciando</label>
                            <p>MXN <strong>$183.99</strong> /mes</p>
                            <p>Facturación anual</p>
                            <div>
								<a href="https://wa.me/5216122186230?text=Me%20interesa%20una%20cotizaci%C3%B3n%20plan%20Inicial" target="_blank" rel="noopener noreferrer" aria-label="Contactar por WhatsApp">Contratar ahora</a>
                            </div>
                        </div>
                        <div className='plan_content_items'>
                            <div className='plan_items'>
                                <label className='plan_item_left'>25</label>
                                <label>Cuentas de correo empresarial</label>
                            </div>
                            <div className='plan_items'>
                                <label className='plan_item_left'><i class="fi fi-rr-infinity"></i></label>
                                <label>Subdominios ilimitados</label>
                            </div>
                            <div className='plan_items'>
                                <label className='plan_item_left'>5</label>
                                <label>GB SSD para guardar los archivos de tu sitio</label>
                            </div>
                            <div className='plan_items'>
                                <label className='plan_item_left'>1</label>
                                <label>Certificado SSL gratuito</label>
                            </div>
							<div className='plan_items'>
                                <label className='plan_item_left'><i class="fi fi-rr-infinity"></i></label>
                                <label>Cuentas FTP ilimitadas</label>
                            </div>
							<div className='plan_items'>
                                <label className='plan_item_left'><i class="fi fi-rr-infinity"></i></label>
                                <label>Bases de datos MySQL ilimitadas</label>
                            </div>
							<div className='plan_items'>
                                <label className='plan_item_left'><i class="fi fi-rr-infinity"></i></label>
                                <label>Transferencia ilimitada</label>
                            </div>
                        </div>
                    </div>     
					<div className='plan_dos'>
                        <div className='plan_amount'>
                            <h2>Avanzado</h2>
							<label>Para desarrollos en crecimiento</label>
                            <p>MXN <strong>$297.50</strong> /mes</p>
                            <p>Facturación anual</p>
                            <div>
								<a href="https://wa.me/5216122186230?text=Me%20interesa%20una%20cotizaci%C3%B3n%20plan%20Avanzado" target="_blank" rel="noopener noreferrer" aria-label="Contactar por WhatsApp">Contratar ahora</a>
                            </div>
                        </div>
                        <div className='plan_content_items'>
                            <div className='plan_items'>
                                <label className='plan_item_left'>50</label>
                                <label>Cuentas de correo empresarial</label>
                            </div>
                            <div className='plan_items'>
                                <label className='plan_item_left'><i class="fi fi-rr-infinity"></i></label>
                                <label>Subdominios ilimitados</label>
                            </div>
                            <div className='plan_items'>
                                <label className='plan_item_left'>20</label>
                                <label>GB SSD para guardar los archivos de tu sitio</label>
                            </div>
                            <div className='plan_items'>
                                <label className='plan_item_left'>1</label>
                                <label>Certificado SSL gratuito</label>
                            </div>
							<div className='plan_items'>
                                <label className='plan_item_left'><i class="fi fi-rr-infinity"></i></label>
                                <label>Cuentas FTP ilimitadas</label>
                            </div>
							<div className='plan_items'>
                                <label className='plan_item_left'><i class="fi fi-rr-infinity"></i></label>
                                <label>Bases de datos MySQL ilimitadas</label>
                            </div>
							<div className='plan_items'>
                                <label className='plan_item_left'><i class="fi fi-rr-infinity"></i></label>
                                <label>Transferencia ilimitada</label>
                            </div>
                        </div>
                    </div>  
					<div className='plan_tres'>
                        <div className='plan_amount'>
                            <h2>Plus</h2>
							<label>Para los que necesitan un alto rendimiento</label>
                            <p>MXN <strong>400.00</strong> /mes</p>
                            <p>Facturación anual</p>
                            <div>
								<a href="https://wa.me/5216122186230?text=Me%20interesa%20una%20cotizaci%C3%B3n%20plan%20Plus" target="_blank" rel="noopener noreferrer" aria-label="Contactar por WhatsApp">Contratar ahora</a>
                            </div>
                        </div>
                        <div className='plan_content_items'>
                            <div className='plan_items'>
                                <label className='plan_item_left'>250</label>
                                <label>Cuentas de correo empresarial</label>
                            </div>
                            <div className='plan_items'>
                                <label className='plan_item_left'><i class="fi fi-rr-infinity"></i></label>
                                <label>Subdominios ilimitados</label>
                            </div>
                            <div className='plan_items'>
                                <label className='plan_item_left'>50</label>
                                <label>GB SSD para guardar los archivos de tu sitio</label>
                            </div>
                            <div className='plan_items'>
                                <label className='plan_item_left'>1</label>
                                <label>Certificado SSL gratuito</label>
                            </div>
							<div className='plan_items'>
                                <label className='plan_item_left'><i class="fi fi-rr-infinity"></i></label>
                                <label>Cuentas FTP ilimitadas</label>
                            </div>
							<div className='plan_items'>
                                <label className='plan_item_left'><i class="fi fi-rr-infinity"></i></label>
                                <label>Bases de datos MySQL ilimitadas</label>
                            </div>
							<div className='plan_items'>
                                <label className='plan_item_left'><i class="fi fi-rr-infinity"></i></label>
                                <label>Transferencia ilimitada</label>
                            </div>
                        </div>
                    </div>                     
             
                </div>
            </div>
        </div>
    </section>
	<section>
		<div className='container'>
			<div class="beneficios_planes_title">
				<h2>Todos los planes incluyen </h2>
                <p>Estamos comprometidos a proporcionarte las herramientas necesarias para que alcances tus metas en línea.</p>
			</div>
			<div className='beneficios_planes'>
				<div className='box_beneficios_planes'>
					<div><img src={helpdesk} alt='Help desk'/></div>
					<div className='box_beneficios_title'>Help desk</div>
					<div>Acceso a nuestro sistema de soporte técnico con atención de 9:00 am a 6:30 pm de lunes a viernes.</div>
				</div>
				<div className='box_beneficios_planes'>
					<div><img src={facturacion} alt='Help desk'/></div>					
					<div className='box_beneficios_title'>Facturación</div>
					<div>Facturación en pesos mexicanos en todos nuestros servicios</div>
				</div>
				<div className='box_beneficios_planes'>
					<div><img src={gestion} alt='Help desk'/></div>
					<div className='box_beneficios_title'>Gestión</div>
					<div>Personal con experiencia técnica y ejecutiva para la gestion de tu infraestructura</div>
				</div>
				<div className='box_beneficios_planes'>
					<div><img src={migracion} alt='Help desk'/></div>
					<div className='box_beneficios_title'>Migración gratuita</div>
					<div>Transfiere tu dominio o página web sin costo</div>
				</div>

			</div>

		</div>
	</section>
	<section>
		<div className='container'>
			<div className='cpanel'>
				<div className='beneficios_cpanel'>
					<h2>Portal de cliente completo</h2>
					<h3>Todas las herramientas que necesitas</h3>
					<ul>
						<li><i class="fi fi-rr-arrow-circle-right"></i> Crea tu cuentas de correo</li>
						<li><i class="fi fi-rr-arrow-circle-right"></i> Agrega y gestiona tus dominios</li>
						<li><i class="fi fi-rr-arrow-circle-right"></i> Crea tus preopios sitios web utilizando wordpress u otra aplicación </li>
						<li><i class="fi fi-rr-arrow-circle-right"></i> Ingresa a FTP, base de datos y recursos nativos de Cpanel</li>
						<li><i class="fi fi-rr-arrow-circle-right"></i> Instala el certificado SSL gratuitamente</li>
					</ul>
				</div>
				<div className='beneficios_cpanel'>
					<img src={cpanel} alt='cpanel para administrar tu sitio web' />
					
				</div>
			</div>
		</div>

	</section>

    <section>
		<div class="container">
			<div class="contact">
				<div class="contact_info">
                    <img src={auditoria} alt='About univesepage' />
					<div>
						<p><i class="fi fi-rr-angle-circle-right"></i> Mejora en el rendimiento y velocidad del sitio.</p>
                        <p><i class="fi fi-rr-angle-circle-right"></i> Aumento de la visibilidad en los motores de búsqueda.</p>
                        <p><i class="fi fi-rr-angle-circle-right"></i> Reforzamiento de la seguridad en línea.</p>
                        <p><i class="fi fi-rr-angle-circle-right"></i> Optimización de la experiencia del usuario.</p>
                        <p><i class="fi fi-rr-angle-circle-right"></i> Toma de decisiones basada en datos analíticos.</p>
					</div>
					

				</div>
				<div class="formulario">
						<div>
							<h2>Envíanos tus dudas o comentarios</h2>
							<p>Completa el formulario de contacto a continuación y cuéntanos lo que necesitas. Estaremos encantados de responder a tus preguntas, proporcionarte asistencia personalizada o brindarte más detalles sobre nuestros servicios.</p>
						</div>
						{alert &&
							<div className='alertMessage'>
								<label>¡Tu formulario ha sido enviado con éxito!</label>
								<p> Nuestro equipo está revisando la información, un agente se pondrá en contacto contigo pronto.</p>
							</div>
						}						
						<div class="form-group">
							<input
								type="text"
								placeholder="Tu nombre"
								value={nombre}
								onChange={(e) => setNombre(e.target.value)}
							/>
							<input
								type="text"
								placeholder="Nombre de tu empresa (opcional)"
								value={empresa}
								onChange={(e) => setEmpresa(e.target.value)}
							/>
							
						</div>
						<div class="form-group">
							<input type='text'
								value={telefono}
								onChange={(e) => setTelefono(e.target.value)} 
								placeholder="Teléfono" />

							<select value={servicio}
								onChange={(e) => setServicio(e.target.value)}>
								<option>¿Qué servicio te interesa?</option>
								<option>Página web</option>
								<option>Tienda en línea</option>
								<option>Desarrollos web personalizados</option>
								<option>Dominio, Hosting y Correo</option>
								<option>Gestión de contenido web</option>
								<option>Auditoría web</option>
								<option>Otro tema</option>
								<option>No lo tengo definido</option>								
							</select>
						</div>
						<div>
							<input type="email"
								placeholder="Email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								/>
						</div>
						<div>
							<textarea 
								placeholder="¿Cómo podemos ayudarte?"
								value={mensaje}
								onChange={(e) => setMensaje(e.target.value)}
								>
							</textarea>
						</div>
						<div className='term-cond'>
							<input type="checkbox" 
							name=""
							checked={aceptoPoliticas}
							onChange={() => setAceptoPoliticas(!aceptoPoliticas)}
							/> Acepto el aviso legal y las política de privacidad. 
						</div>
						<div class="">
							
							<button
								class="btn_enviar"
								disabled={!aceptoPoliticas}
								onClick={enviarCorreo}
								>
								Enviar
								</button>

						</div>
					</div>
			</div>
		</div>
	</section>




    
    <Footer />
  </>
  )
}
