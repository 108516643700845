import React, {useState} from 'react'
import axios from 'axios';
import contacto from './assets/images/contacto.webp'
import './assets/css/contacto.css'
import Footer from './Footer'

export default function Contacto () {
	const [nombre, setNombre] = useState('');
	const [empresa, setEmpresa] = useState('');
	const [telefono, setTelefono] = useState('');
    const [servicio, setServicio] = useState('');
	const [email, setEmail] = useState('');
	const [mensaje, setMensaje] = useState('');
	const [aceptoPoliticas, setAceptoPoliticas] = useState(true);

	const [alert, setAlert] = useState(false);
 {/*await axios.post('http://localhost:3001/enviar-correo', {*/}

	const enviarCorreo = async () => {

        try {
            await axios.post('https://3cwma7t8s4.execute-api.us-east-1.amazonaws.com/prod/webservice', {
                nombre,
                empresa,
                telefono,
				servicio,
                email,
                mensaje,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    // Agrega otros encabezados si son necesarios
                },
            });
		
    		
            // Limpiar campos después de enviar el correo
            setNombre('');
            setEmpresa('');
            setTelefono('');
			setServicio('');
            setEmail('');
            setMensaje('');
    
            setAlert(true);
        } catch (error) {
            console.error('Error al enviar el correo:', error);
            alert('Error al enviar el correo');
        }
    };

    
  return (
    <>
	    <section className='headers'>
            <div className='container'>
                <div className='title'>
                    <h1>Contacto</h1>
                    <p>Nuestro propósito es lo que nos hace levantarnos cada mañana, <br/> y esforzarnos por ofrecer un futuro más digital.. </p>
                </div>
            </div>
        </section>
		<section>
			<div class="container">
				<div class="contact">
					<div class="contact_info">
						<div>
							<img src={contacto} alt='universepage contacto' />
						</div>
						<h2>¡Contactános, <br /> queremos escucharte!</h2>
						<p>¿Quieres saber más sobre nosotros? o ¿Te interesa uno de nuestros servicios? Déjanos tus datos y nos pondremos en contacto contigo.</p>
						<div class="call_phone">
							<p><i class="fa-solid fa-phone"></i></p>
							<p>612 197 00 06</p>
						</div>
						<div class="call_mail">
							<p><i class="fa-solid fa-envelope"></i></p>
							<p>contacto@universepage.com.mx</p>
						</div>					
						<div className='social'>
							<div className='social_icon_contac'>
								<a href="https://www.facebook.com/universepage.mx/" target="_blank" rel="noopener noreferrer" aria-label="Facebook de universepage">
									<i class="fi fi-brands-facebook"></i>
								</a>
							</div>
							<div className='social_icon_contac'>
								<a href="https://www.instagram.com/universepage.mx?igsh=MXFjc3dicWR0MmgzbQ==" target="_blank" rel="noopener noreferrer" aria-label="instagram de universepage">
									<i class="fi fi-brands-instagram"></i>
								</a>
							</div>
							<div className='social_icon_contac'>
								<a href="https://wa.me/5216121970006?text=Hola" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp de universepage">
								<i class="fi fi-brands-whatsapp"></i>
								</a>
							</div>
						</div>   

					</div>
					<div class="formulario">
						<div>
							<h2>Envíanos tus dudas o comentarios</h2>
							<p>Completa el formulario de contacto a continuación y cuéntanos lo que necesitas. Estaremos encantados de responder a tus preguntas, proporcionarte asistencia personalizada o brindarte más detalles sobre nuestros servicios.</p>
						</div>
						{alert &&
							<div className='alertMessage'>
								<label>¡Tu formulario ha sido enviado con éxito!</label>
								<p> Nuestro equipo está revisando la información, un agente se pondrá en contacto contigo pronto.</p>
							</div>
						}						
						<div class="form-group">
							<input
								type="text"
								placeholder="Tu nombre"
								value={nombre}
								onChange={(e) => setNombre(e.target.value)}
							/>
							<input
								type="text"
								placeholder="Nombre de tu empresa (opcional)"
								value={empresa}
								onChange={(e) => setEmpresa(e.target.value)}
							/>
							
						</div>
						<div class="form-group">
							<input type='text'
								value={telefono}
								onChange={(e) => setTelefono(e.target.value)} 
								placeholder="Teléfono" />

							<select value={servicio}
								onChange={(e) => setServicio(e.target.value)}>
								<option>¿Qué servicio te interesa?</option>
								<option>Página web</option>
								<option>Tienda en línea</option>
								<option>Desarrollos web personalizados</option>
								<option>Dominio, Hosting y Correo</option>
								<option>Gestión de contenido web</option>
								<option>Auditoría web</option>
								<option>Otro tema</option>
								<option>No lo tengo definido</option>								
							</select>
						</div>
						<div>
							<input type="email"
								placeholder="Email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								/>
						</div>
						<div>
							<textarea 
								placeholder="¿Cómo podemos ayudarte?"
								value={mensaje}
								onChange={(e) => setMensaje(e.target.value)}
								>
							</textarea>
						</div>
						<div className='term-cond'>
							<input type="checkbox" 
							name=""
							checked={aceptoPoliticas}
							onChange={() => setAceptoPoliticas(!aceptoPoliticas)}
							/> Acepto el aviso legal y las política de privacidad. 
						</div>
						<div class="">
							
							<button
								class="btn_enviar"
								disabled={!aceptoPoliticas}
								onClick={enviarCorreo}
								>
								Enviar
								</button>

						</div>
					</div>
				</div>
			</div>
		</section>
	<Footer />
    </>
  )
}
