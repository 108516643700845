import React from 'react'
import responsive from './assets/images/responsive.webp'
import analitycs from './assets/images/analitycs.webp'
import capacitacion from './assets/images/capacitacion.webp'
import soporte from './assets/images/soporte.webp'

export default function More_services() {
  return (
    <>
    	<section className="more_services" id="more_services">
		<div className="container">
			<div >
				<h2>Nuestros servicios siempre incluyen</h2>
			</div>
			
			<div className="more_services_list">
				<div className="box_more_services">
					<div className='more_service_img'>
						<img src={capacitacion} alt='capacitacion universepage'/>
					</div>
					<div className="more_service_title">
						<div> Capacitación</div>
					</div>
					<div className="more_service_text">
						<p>Te guiaremos a través de sesiones personalizadas, brindándote las herramientas y conocimientos necesarios para comprender y administrar tu sitio.</p>
					</div>
				</div>
				<div className="box_more_services">
					<div className='more_service_img'>
						<img src={analitycs} alt='capacitacion universepage'/>
					</div>
					<div className="more_service_title">
						<div> Analytics</div>
					</div>
					<div className="more_service_text">
						<p>Con nuestro enfoque orientado a los datos, podrá obtener información valiosa sobre el rendimiento de su sitio web y tomar decisiones informadas. </p>
					</div>	
				</div>
				<div className="box_more_services">
					<div className='more_service_img'>
						<img src={responsive} alt='capacitacion universepage'/>
					</div>
					<div className="more_service_title">
						<div>Diseños Responsivos</div>
					</div>
					<div className="more_service_text">
						<p>Nuestros diseñadores contemplan el diseño adaptado a los diferentes tamaños de los dispositivos como celular, tablets, latops y computadores.</p>
					</div>
				</div>	
				<div className="box_more_services">
					<div className='more_service_img'>
						<img src={soporte} alt='capacitacion universepage'/>
					</div>
					<div className="more_service_title">
						<div>Soporte</div>
					</div>
					<div className="more_service_text">
						<p>Para garantizar una transición sin problemas y una experiencia de usuario óptima, ofrecemos un soporte completo durante los primeros 12 días posteriores al lanzamiento de su sitio.</p>
					</div>
						
				</div>			
			</div>
		</div>
	</section>
    </>
  )
}
