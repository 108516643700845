import React, {useState} from 'react'
import axios from 'axios';
import '../../assets/css/pagina_web.css'
import administracion from '../../assets/images/administracion.png'
import html from '../../assets/images/html.png'
import css from '../../assets/images/css.png'
import js from '../../assets/images/js.png'
import react from '../../assets/images/react.png'
import node from '../../assets/images/node.png'
import postgresql from '../../assets/images/postgresql.png'
import mysql from '../../assets/images/mysql.png'
import auditoria from '../../assets/images/auditoria.jpg'
import Footer from '../../Footer'
export default function GestionWeb() {
    const [nombre, setNombre] = useState('');
	const [empresa, setEmpresa] = useState('');
	const [telefono, setTelefono] = useState('');
    const [servicio, setServicio] = useState('');
	const [email, setEmail] = useState('');
	const [mensaje, setMensaje] = useState('');
	const [aceptoPoliticas, setAceptoPoliticas] = useState(true);

	const [alert, setAlert] = useState(false);
 {/*await axios.post('http://localhost:3001/enviar-correo', {*/}

	const enviarCorreo = async () => {

        try {
            await axios.post('https://3cwma7t8s4.execute-api.us-east-1.amazonaws.com/prod/webservice', {
                nombre,
                empresa,
                telefono,
				servicio,
                email,
                mensaje,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    // Agrega otros encabezados si son necesarios
                },
            });
		
    		
            // Limpiar campos después de enviar el correo
            setNombre('');
            setEmpresa('');
            setTelefono('');
			setServicio('');
            setEmail('');
            setMensaje('');
    
            setAlert(true);
        } catch (error) {
            console.error('Error al enviar el correo:', error);
            alert('Error al enviar el correo');
        }
    };
  return (
    <>
    <section className='headers'>
        <div className='container'>
            <div className='title'>
                <h1>Administración de sitios</h1>
                <p>Nuestro propósito es lo que nos hace levantarnos cada mañana, <br/> y esforzarnos por ofrecer un futuro más digital. </p>
            </div>
        </div>
    </section>
    <section>
      <div className='container'>

          <div class="content_about">
              <div class="about_img">
                  <img src={administracion} alt='About univesepage' />
              </div>
              <div class="about_text_box">
                  <div class="about_text">
                      <h2 style={{textAlign:'initial'}}> DESDE EL DESARROLLO HASTA LA PERFECCIÓN</h2>
                      <h4>Administración de sitios</h4>
                      <p>Para nosotros la gestión de contenido web es un compromiso integral, desde el desarrollo hasta la implementación. Permitimos que te enfoques en tu creatividad mientras nos encargamos de los aspectos técnicos y de desarrollo. Con nosotros, tu presencia en línea está en constante evolución, siempre lista para impresionar y lograr los objetivos.</p>
                      {
                          /*
                          <!--<p>Descubre nuestro equipo experto en el desarrollo y diseño web.</p>-->
                          */
                      }
                      <p>Entendemos que la gestión de contenido web va más allá de la simple publicación. Desde el desarrollo inicial hasta las actualizaciones continuas y el soporte técnico, nos comprometemos a cuidar cada detalle para garantizar un rendimiento óptimo y una experiencia de usuario excepcional.</p>
                      
                      <a href="#servicios" class="btn_about"  aria-label="categorias universepage">Ver más</a>
                  </div>
                  
              </div>
          </div>
      </div>
  </section>
  <section class="services" id='servicios'>
		<div class="container">
			<div class="">
				<h2>Servicios integrados</h2>
                <p>Nuestro plan de Administración de Sitios Web es la respuesta ideal para empresas o negocios ocupados que buscan mantener su presencia en línea sin preocupaciones. Diseñado para todo tipo de sitios web, nuestro servicio ofrece un enfoque integral que va más allá del mantenimiento técnico.</p>
			</div>
			
			<div class="services_list">
				<div class="box_service">
					<div class="service_title">
						<div class="service_icon"> <i class="fa-solid fa-clipboard-check"></i></div>
						<div>Gestión completa</div>
					</div>
					<div class="service_text">
                        <p>Nos encargamos de cada aspecto de la administración de su sitio web para garantizar un rendimiento óptimo y una experiencia de usuario excepcional.</p>
					</div>		
				</div>

				<div class="box_service">
					<div class="service_title">
						<div class="service_icon"> <i class="fa-solid fa-clone"></i></div>
						<div>Copias de seguridad</div>
					</div>
					<div class="service_text">
						<p>Realizamos copias de seguridad de su sitio de manera regular, asegurando que sus datos estén siempre respaldados. </p>
					</div>
				</div>
				<div class="box_service">
					<div class="service_title">
						<div class="service_icon"> <i class="fa-solid fa-screwdriver-wrench"></i></div>
						<div>Soporte técnico</div>
					</div>
					<div class="service_text">
						<p>Con nuestro soporte prioritario, tiene acceso rápido a nuestro equipo de expertos para abordar cualquier problema de mantenimiento web que pueda surgir, brindándole tranquilidad y una solución eficiente. </p>
					</div>

				</div>	
			</div>
			<div class="services_list">
				<div class="box_service">
					<div class="service_title">
						<div class="service_icon"> <i class="fa-solid fa-database"></i></div>
						<div>Optimización de la Base de Datos</div>
					</div>
					<div class="service_text">
						<p>Nuestra limpieza regular de la base de datos garantiza que su sitio web funcione sin problemas y sin la acumulación innecesaria de datos. Esto no solo mejora el rendimiento, sino que también contribuye a una experiencia de usuario más rápida y eficiente.</p>
					</div>		
				</div>
				<div class="box_service">
					<div class="service_title">
						<div class="service_icon"> <i class="fa-solid fa-arrows-rotate"></i></div>
						<div>Actualizaciones programadas</div>
					</div>
					<div class="service_text">
						<p>Aplicamos actualizaciones periódicas para mantener su sitio seguro y al día con las últimas tecnologías.</p>
					</div>
				</div>
				<div class="box_service">
					<div class="service_title">
						<div class="service_icon"> <i class="fa-solid fa-arrows-up-to-line"></i></div>
						<div>Adaptabilidad y Crecimiento</div>
					</div>
					<div class="service_text">
						<p>Diseñamos nuestro servicio para ser escalable, asegurándonos de que su sitio web pueda crecer y evolucionar según las necesidades de su negocio. Estamos aquí para apoyarle en cada paso del camino.</p>
					</div>
				</div>	
			</div>
		</div>
	</section>

    <section>
		<div class="container">
			<div class="contact">
				<div class="contact_info">
                    <img src={auditoria} alt='About univesepage' />
					<div>
						<p><i class="fi fi-rr-angle-circle-right"></i> Ahorro de tiempo.</p>
                        <p><i class="fi fi-rr-angle-circle-right"></i> Solución de problemas.</p>
                        <p><i class="fi fi-rr-angle-circle-right"></i> Soporte técnico.</p>
                        <p><i class="fi fi-rr-angle-circle-right"></i> Diseño y desarrollo.</p>
                        <p><i class="fi fi-rr-angle-circle-right"></i> Escalabilidad.</p>
					</div>					
				</div>
				<div class="formulario">
						<div>
							<h2>Envíanos tus dudas o comentarios</h2>
							<p>Completa el formulario de contacto a continuación y cuéntanos lo que necesitas. Estaremos encantados de responder a tus preguntas, proporcionarte asistencia personalizada o brindarte más detalles sobre nuestros servicios.</p>
						</div>
						{alert &&
							<div className='alertMessage'>
								<label>¡Tu formulario ha sido enviado con éxito!</label>
								<p> Nuestro equipo está revisando la información, un agente se pondrá en contacto contigo pronto.</p>
							</div>
						}						
						<div class="form-group">
							<input
								type="text"
								placeholder="Tu nombre"
								value={nombre}
								onChange={(e) => setNombre(e.target.value)}
							/>
							<input
								type="text"
								placeholder="Nombre de tu empresa (opcional)"
								value={empresa}
								onChange={(e) => setEmpresa(e.target.value)}
							/>
							
						</div>
						<div class="form-group">
							<input type='text'
								value={telefono}
								onChange={(e) => setTelefono(e.target.value)} 
								placeholder="Teléfono" />

							<select value={servicio}
								onChange={(e) => setServicio(e.target.value)}>
								<option>¿Qué servicio te interesa?</option>
								<option>Página web</option>
								<option>Tienda en línea</option>
								<option>Desarrollos web personalizados</option>
								<option>Dominio, Hosting y Correo</option>
								<option>Gestión de contenido web</option>
								<option>Auditoría web</option>
								<option>Otro tema</option>
								<option>No lo tengo definido</option>								
							</select>
						</div>
						<div>
							<input type="email"
								placeholder="Email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								/>
						</div>
						<div>
							<textarea 
								placeholder="¿Cómo podemos ayudarte?"
								value={mensaje}
								onChange={(e) => setMensaje(e.target.value)}
								>
							</textarea>
						</div>
						<div className='term-cond'>
							<input type="checkbox" 
							name=""
							checked={aceptoPoliticas}
							onChange={() => setAceptoPoliticas(!aceptoPoliticas)}
							/> Acepto el aviso legal y las política de privacidad. 
						</div>
						<div class="">
							
							<button
								class="btn_enviar"
								disabled={!aceptoPoliticas}
								onClick={enviarCorreo}
								>
								Enviar
								</button>

						</div>
					</div>
			</div>
		</div>
	</section>


    <section className='herramientas_tecnologias'>
      <div className='container'>
          <div className='herramientas'>
              <div className='herramientas_text'>
                  <h4>HERRAMIENTAS Y TECNOLOGIAS</h4>
                  <div className='herramientas_text_content'>
                      <p>Nuestras habilidades y experiencia nos permiten ofrecer soluciones web a través de diversas tecnologías para el desarrollo de software, atractivas, robustas, seguras y escalables.</p>
                  </div>
                 

              </div>
              <div className='herramientas_img'>
                  <div className='herramientas_box'>
                      <img src={html} alt='html' />
                      <p>HTML</p>
                  </div>
                  <div className='herramientas_box'>
                      <img src={css} alt='css' />
                      <p>CSS</p>
                  </div>
                  <div className='herramientas_box'>
                      <img src={js} alt='java script' />
                      <p>JavaScript</p>
                  </div>
                  <div className='herramientas_box'>
                      <img src={react} alt='react' />
                      <p>React</p>
                  </div>
                  <div className='herramientas_box'>
                      <img src={node} alt='Node Js' />
                      <p>Node JS</p>
                  </div>
                  <div className='herramientas_box'>
                      <img src={postgresql} alt='PostgreSQL' />
                      <p>PostgreSQL</p>
                  </div>
                  <div className='herramientas_box'>
                      <img src={mysql} alt='mySQL' />
                      <p>MySQL</p>
                  </div>
              </div>
              <div className='herramientas_text'>                    
                  <div className='herramientas_text_content'>
                      <p></p>
                  </div>
              </div>
          </div>

      </div>
    </section>
    
    <Footer />
  </>
  )
}
