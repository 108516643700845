import React from 'react'
import HeroVideo from './assets/videos/hero.mp4'
import hero from './assets/images/hero.webp'

export default function Hero() {
	/*
	const [heroCargada, setHeroCargada] = useState(false);
	useEffect(() => {
		// Precargar la imagen al montar el componente
		const heroImg = new Image();
		heroImg.src = hero;
		// Cuando la imagen se carga, actualizamos el estado
		heroImg.onload = () => {
			setHeroCargada(true);
		};
	
		// Otra opción utilizando el método fetch para precargar la imagen
		// fetch('ruta_de_tu_imagen.jpg');
	
		// Puedes agregar más imágenes según tus necesidades
	
		// Limpieza al desmontar el componente (opcional)
		return () => {
		  // Realizar tareas de limpieza si es necesario
		};
	  }, []);
	  */

  return (
    <>
    <section class="hero">

		<video width="100%" height="auto" autoPlay={true} controls={false} loop muted>
			<source src={HeroVideo} type="video/mp4" />
		</video>
		<div className='hero_img'>
		<img src={hero} alt="soluciones web universepage" />
			{/*
			{heroCargada ? (
			<img src={hero} alt="Descripción de la imagen" />
		) : (
			<p>Cargando imagen...</p>
		)}*/}
		</div>
		<div class="container">
			<div class="hero_content">
				<div class="text_hero_box">
					<div class="text_hero">
						<h1>SOLUCIONES DE DESARROLLO WEB</h1>
						<p>Construyendo tu presencia en línea: 
						Descubre las soluciones a la medida que te ayudarán a destacar en el mundo digital. </p>						 
						<div>
							<a href="#services" class="btn_hero" aria-label="servicios universepage">Ver servicios</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		
	</section>
    </>
  )
}
