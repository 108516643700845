import React from 'react'

export default function Btn_flotante() {
  return (
    <>
    	<section>
        <div className="fab-container">
            <div className="fab shadow">
              <div className="fab-content">
                <span className="material-icons">support_agent</span>
              </div>
            </div>
            <div className="sub-button shadow">
               <a href="tel:6121970006" className="btn-llamar" onclick="return (navigator.userAgent.match(/Android | IPhone | movile /i)) != null;">
                <span className="material-icons">phone</span>
              </a>
            </div>
            <div className="sub-button shadow">
              <a href="mailto:contacto@universepage.com.mx" rel='noopener noreferrer' target="_blank" aria-label="Contactar por correo">
                <span className="material-icons">mail_outline</span>
              </a>
            </div>
            <div className="sub-button shadow">
              <a href="https://wa.me/5216121970006?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel='noopener noreferrer' aria-label="Contactar por WhatsApp">
                
                <i className="fa-brands fa-whatsapp"></i>
              </a>
            </div>
      
        </div>
	</section>
    </>
  )
}
