import React from 'react'
import aboutImg from './assets/images/about.jpg'
import { Outlet, Link } from "react-router-dom";
export default function About() {
  return (
    <>
    <section class="about">
		<div class="container">
			<div class="content_about">
				<div class="about_img">
					<img src={aboutImg} alt='About univesepage' />
				</div>
				<div class="about_text_box">
					<div class="about_text">
						<h2 style={{color:'#2874A6'}}>BIENVENIDO A UNIVERSEPAGE</h2>
						<p>En UNIVERSEPAGE, nos dedicamos a brindar soluciones de desarrollo web de vanguardia. Nuestro equipo de expertos apasionados por la tecnología trabaja incansablemente para convertir tus ideas en realidades digitales sorprendentes pero sobre todo funcionales.</p>
						{
							/*
							<!--<p>Descubre nuestro equipo experto en el desarrollo y diseño web.</p>-->
							*/
						}
						<p>Nuestro enfoque personalizado garantiza que cada proyecto se maneje con la máxima atención y cuidado.</p>
						<p>Tenemos las habilidades y la experiencia para brindarte resultados excepcionales.</p>						
						<Link to="/nosotros" class="btn_about">Conócenos </Link>						
					</div>
					
				</div>
			</div>

		</div>
	</section>
	<Outlet />
    </>
  )
}
