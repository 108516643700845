import React from 'react'
import { Outlet, Link } from "react-router-dom";
export default function CallToAction() {
  return (
    <>
    <section class="call_to_action">
		<div class="container">
			<div class="text_call_to_action">
				<h2>¿Estás listo?</h2>
				<h3>Contáctanos ahora mismo para realizar una cotización</h3>
				<p> Puedes encontrarnos en <strong>La Paz, B.C.S</strong>. pero ofrecemos servicios en todo <strong>México</strong>, nuetsros principales clientes estan en: <strong> Los Cabos</strong>, <strong>Guadalajara</strong>, <strong>Monterrey</strong>, <strong>Los 
				Mochis</strong>. </p>
				<div>
					<Link to="/cotizador">Solicitar cotización </Link>
				</div>
				
			</div>
		</div>
		
	</section>
	<Outlet />
	</>
  )
}
