import React from 'react'
import Hero from './Hero'
import About from './About'
import Servicios from './Servicios';
import More_services from './More_services'
import CallToAction from './CallToAction'
import Cronograma from './Cronograma'
import Btn_flotante from './Btn_flotante'
import FAQSection from './FAQSection';
import Footer from './Footer';

export default function Home() {
  return (
    <>
    <Hero />
    <About />
    <Servicios />
    <More_services />
    <CallToAction />
    <Cronograma />
    
    <FAQSection />

    <Btn_flotante />
    <Footer />
    

    </>
  )
}
