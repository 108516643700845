import React from 'react'
import cronograma from './assets/images/cronograma.webp'
import { Outlet, Link } from "react-router-dom";

export default function Cronograma() {
  return (
    <>
    	<section class="cronograma">
		<div class="container">
			<div className='cronograma_box'>
				<div className='cronograma_text'>
					<div>
						<h2>¡Seguimiento en tiempo real!</h2>
						<h4>Cronograma de actividades</h4>
						<p>Sabemos la importancia que tiene cada etapa en la construcción de cada desarrollo, por eso cuando trabajas con nosotros tendrás acceso a una plataforma donde podrás dar seguimiento a cada proceso y monitorear las actividades en curso.</p>
						<Link to="/contacto" class="btn_about">Contactános </Link>
					</div>
				</div>
				<div class="cronograma_img">
					<img src={cronograma} alt='cronograma universepage'/>
					
				</div>
			</div>
		</div>

	</section>
	<Outlet />
    </>
  )
}
